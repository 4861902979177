@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Rubik+Moonrocks&display=swap');

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icons/icons.eot");
  src: url("../../fonts/icons/icons.eot") format("embedded-opentype"),
    url("../../fonts/icons/icons.ttf") format("truetype"),
    url("../../fonts/icons/icons.woff") format("woff"),
    url("../../fonts/icons/icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-save:before {
  content: "\e900";
}
.icon-upload:before {
  content: "\e901";
}
.icon-account:before {
  content: "\e902";
}
.icon-arrow-back:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e904";
}
.icon-faqs:before {
  content: "\e905";
}
.icon-info:before {
  content: "\e906";
}
.icon-log-out:before {
  content: "\e907";
}
.icon-messages:before {
  content: "\e908";
}
.icon-minus:before {
  content: "\e909";
}
.icon-new-tab:before {
  content: "\e90a";
}
.icon-plus:before {
  content: "\e90b";
}
.icon-reports:before {
  content: "\e90c";
}
.icon-subscriptions:before {
  content: "\e90d";
}
.icon-support:before {
  content: "\e90e";
}
.icon-template-settings:before {
  content: "\e90f";
}
.icon-warning:before {
  content: "\e910";
}
.icon-visible:before {
  content: "\e911";
}
.icon-check:before {
  content: "\e912";
}
.icon-close:before {
  content: "\e913";
}