// Default font styles
$default-font-size: 18px;
$default-font-family: 'Poppins', sans-serif;
$default-font-weight: 400;

// Dark theme colors
$primary-color: #575f7f;
$secondary-color: rgba(64, 74, 101, .6);
$light-grey: rgba(255,255,255,.7);
$background-left-color: #2D3854;
$background-right-color: #181E2C;
$disabled-tab: rgba(255,255,255,.2);
$card-background: rgba(255,255,255, .6);
$input-background:rgba(255,255,255, .7);
$blue:#5A93FF;
$blue-2:#316beb;
$green: #00D372;
$green-2: #02b160;
$error-red: #ff5353;
$notification-background: #fff;
$warning: #d38000;
// $yellow: #FFC400;
// $yellow: #FFDB12;
$yellow: #ffb212;

// Light theme colors
$primary-color-light: #36415D;
$secondary-color-light: rgba(54, 65, 93, .5);
$border-color-light: rgba(54, 65, 93, .2);
$background-color-light: #F1F5F9;
