* {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    // font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: $default-font-family;
    font-weight: $default-font-weight;
    @extend .primary-color;
    line-height: 1.2;
    font-size: 16px;
    img {
        max-width: 100%;
        height: auto;
    }
}

h1, h2, h3, h4, h5, h5 {
    font-weight: 500;
}


.content {
    &:before {
        content:'';
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        @extend .gradient-background;
        z-index: -999;
    }
    &:after {
        content:'';
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        background-color:#fff;
        opacity: .4;
        z-index: -998;
    }
    a {
        text-decoration: none;
        &:not(.link) {
            @extend .primary-color;
        }
        
        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }
    }
    .hover-effect {
        transition:opacity .2s ease;
        &:hover {
            opacity: .8;
        }
    }
    .content-wrapper {
        padding: 40px;
        @media (min-width:641px) and (max-width: 1024px) {
            padding: 20px;
        }
        @media (max-width: 640px) {
            padding: 20px 5px;
        }
        .main-content {
            max-width:1600px;
            margin: 80px auto 0;
            border-radius: 20px;
            background-color: rgba(255,255,255, .6);
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            overflow: hidden;
            position: relative;
            height: 100%;
            .view {
                width: 85%;
                position: relative;
                .content-spacing {
                    padding: 80px 20px;
                }
                @media (max-width: 1024px) {
                    width: calc(100% - 40px);
                }
                .card {
                    margin-left: 20px;
                    margin-right: 20px;
                    @media (max-width: 640px) {
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .copyrights {
            margin: 80px 0 0;
            text-align: center;
            color: #fff;
        }
    }
}
.text-center {
    text-align:center;
}
.text-right {
    text-align:right;
}
.text-left {
    text-align:left;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-80 {
    margin-top:80px;
}
.mb-80 {
    margin-bottom:80px;
}
.mb-5 {
    margin-bottom: 5px;
}
.crs-pointer {
    cursor: pointer;
}

.overflow-hidden {
    overflow: hidden;
}

.no-outline {
    outline: none;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-align-end {
    justify-content: flex-end;
}

.inline-block {
    display: inline-block;
}

.link {
    font-size: inherit;
    display: inline-block;
    transition: opacity .2s ease;
    color:$blue;
    position: relative;
    cursor: pointer;
    span {
        display: inline-block;
        vertical-align: middle;
    }
    .icon {
        font-size: 16px;
        margin-left: 5px;
    }
    i {
        font-size: 12px;
        margin-left: 10px;
    }
    &:hover {
        opacity: .5;
    }
    &.disabled {
        cursor: default;
        opacity: .6;
    }
    &.loading {
        opacity: 1;
        span {
            opacity: 0;
        }
        &:after {
            content: '';
            @extend .button-loader-blue;
        }
    }
}

.primary-button {
    display: inline-block;
    width: auto;
    min-width: 200px;
    border-radius: 4px;
    font-size: 18px;
    height: 50px;
    line-height: 30px;
    padding: 10px 20px;
    text-align: center;
    color: $primary-color;
    cursor: pointer;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    border: none;
    outline: none;
    box-shadow: none;
    font-weight: 400;
    &.green-button {
        background: $green;
        transition: background .2s ease;
        &:hover {
            background: $green-2;
        }
        &:disabled {
            color:$secondary-color;
            background:$disabled-tab;
            cursor: default;
        }
    }
    &.loading {
        span {
            opacity: 0;
        }
        &:after {
            content: '';
            @extend .button-loader;
        }
    }
    &.blue-button {
        color: #fff;
        background: $blue;
        box-shadow: 0 3px 6px rgba(100, 102, 152, .3);
        transition: background .2s ease;
        &:hover {
            background: $blue-2;
        }
        &.disabled, &:disabled {
            box-shadow:none;
            cursor: default;
            background-color: #90b6ff;
        }
    }

    &.red-button {
        color: #fff;
        background: #f2545b;
        box-shadow: 0 3px 6px rgba(100, 102, 152, .3);
        transition: background .2s ease;
        &:hover {
            background: #ff5962;
        }
        &.disabled, &:disabled {
            box-shadow:none;
            cursor: default;
            background-color: #f2545b;
        }
    }
    &.orange-button {
        color: #fff;
        background: $warning;
        box-shadow: 0 3px 6px rgba(100, 102, 152, .3);
        transition: background .2s ease;
        &:hover {
            background: #f89700;
        }
        &.disabled, &:disabled {
            box-shadow:none;
            cursor: default;
            background-color: #d2a258;
        }
    }
    &.medium-button {
        font-size: 16px;
        height: auto;
        min-width: 180px;
        line-height: 20px;
        @media (max-width: 640px) {
            padding: 8px 16px;
            min-width: auto;
            font-size: 14px;
            line-height: 1.2;
        }
    }
    &.small-button {
        font-size: 14px;
        height: auto;
        min-width: 100px;
        line-height: 1.1;
    }
}

.secondary-button {
    display: inline-block;
    width: auto;
    min-width: 200px;
    border-radius: 4px;
    font-size: 18px;
    height: 50px;
    line-height: 30px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    border: none;
    outline: none;
    box-shadow: none;
    font-weight: 400;
    &.green-button {
        background: $green;
        transition: background .2s ease;
        &:hover {
            background: $green-2;
        }
        &:disabled {
            color:$secondary-color;
            background:$disabled-tab;
            cursor: default;
        }
    }
    &.loading {
        span {
            opacity: 0;
        }
        &:after {
            content: '';
            @extend .button-loader;
        }
    }
    &.blue-button {
        color: $blue;
        background: rgba(90, 147, 255, 0);
        box-shadow: inset 0 0 0 1px;
        transition: background .2s ease;
        &:hover {
            background: rgba(90, 147, 255, .1);
        }
        &.disabled, &:disabled {
            box-shadow:none;
            cursor: default;
            background-color: #90b6ff;
            background: rgba(90, 147, 255, .2);
            opacity: .9;
        }
    }
    &.medium-button {
        font-size: 16px;
        height: auto;
        min-width: 180px;
        line-height: 20px;
        @media (max-width: 640px) {
            padding: 8px 16px;
            min-width: auto;
            font-size: 14px;
            line-height: 1.2;
        }
    }
    &.small-button {
        font-size: 14px;
        height: auto;
        min-width: 100px;
        line-height: 1.1;
    }
}

.card-shadow {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 20%;
        width: 94%;
        left: 3%;
        height: 80%;
        display: block;
        background: rgba(0, 0, 0, 0);
        @extend .card-box-shadow;
        z-index: -1;
        border-radius: 4px;
    }
}

.button-shadow {
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: .2s;
    &:hover {
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
    }
}

.loader-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #5A93FF, #69CBFF, #FF53E4);
    z-index: 1000;
    top:0;
    left: 0;
    &:after {
        content:'';
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        background-color:#fff;
        opacity: .4;
        z-index: 1;
    }
    .loader {
        z-index: 2;
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -35px 0 0 -35px;
    z-index: 10;
    width:70px; 
    height:70px;
    border-radius:50%;
    border-top: 3px solid rgba(255, 255, 255, 0.5);
    border-right: 3px solid rgba(255, 255, 255, 0.5);
    border-bottom: 3px solid rgba(255, 255, 255, 0.5);
    border-left: 3px solid #ffffff;
    -webkit-animation: loader-animation 2s infinite linear;
    animation: loader-animation 2s infinite linear;
}

.button-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
    z-index: 10;
    width:20px; 
    height:20px;
    border-radius:50%;
    border-top: 2px solid rgba(255, 255, 255, 0.4);
    border-right: 2px solid rgba(255, 255, 255, 0.4);
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    border-left: 2px solid #ffffff;
    -webkit-animation: loader-animation 2s infinite linear;
    animation: loader-animation 2s infinite linear;
    box-sizing: border-box;
}
.button-loader-blue {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
    z-index: 10;
    width:20px; 
    height:20px;
    border-radius:50%;
    border-top: 3px solid rgba(90, 147, 255, 0.3);
    border-right: 3px solid rgba(90, 147, 255, 0.3);
    border-bottom: 3px solid rgba(90, 147, 255, 0.3);
    border-left: 3px solid rgba(90, 147, 255, 1);
    -webkit-animation: loader-animation 2s infinite linear;
    animation: loader-animation 2s infinite linear;
    box-sizing: border-box;
}

@-webkit-keyframes loader-animation { 
    100% { -webkit-transform: rotate(360deg); } }
@keyframes loader-animation { 
    100% { transform: rotate(360deg); transform:rotate(360deg); } }

.alert {
    padding: 20px;
    border-radius: 2px;
    @extend .card-shadow;
    position: fixed;
    left:50px;
    bottom:50px;
    z-index: 99;
}

.small-container {
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.large-container {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.card {
    @extend .card-background;
    margin-bottom: 40px;
    border-radius: 10px;
    .card-heading {
        font-size: 20px;
        font-weight: 500;
        padding-top: 40px;
        margin: 40px 0;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px;
        h2 {
            font-size: 20px;
            font-weight: 500;
            margin: 0;
        }
        .icon-plus {
            font-size: 16px;
            transition: .2s ease;
        }
        &.opened {
            padding: 40px 40px 0;
            margin: 0 0 40px;
            .icon-plus {
                transform: rotate(45deg);
            }
        }
    }
    .card-body {
        padding: 0 40px 40px;
        .faq-form {
            &:not(.no-margin) {
                margin: 0 0 -20px;
            }
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 767px) {
        .card-header {
            h2 {
                font-size: 16px;
            }
            &.opened {
                padding: 20px 20px 0;
                margin: 0 0 40px;
            }
        }
        .card-body {
            padding: 0 20px 20px;
        }
    }
}

.dashboard-tabs {
    border-left: 2px solid transparent;
    margin: 2px 0 0;
    > div {
        // @extend .card-background;
        background: rgba(255,255,255,.3);
        padding: 0 30px;
    }
    a, span {
        display: inline-block;
        font-size: 16px;
        padding: 15px 15px 15px;
        position: relative;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -1px;
            left: 0;
            background: transparent;
        }
        &.active {
            cursor: default;
            color:$blue;
            &:before {
                background: $blue;
            }
        }
    }
    &.no-border-bottom {
        border: none;
        a, span {
            &:before {
                background: transparent;
            }
            &.active {
                color:$primary-color;
                &:before {
                    background: $primary-color;
                }
            }
        }
    }
}

.switcher {
    background: #D3E1F5;
    position: relative;
    width: 60px;
    height: 30px;
    border-radius: 25px;
    &.on {
        background: #00D372;
        .control {
            box-shadow: 0 0 6px rgba(0,0,0,.20);
        }
    }
    cursor: pointer;
    .control {
        position: absolute;
        top:5px;
        left: 7px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        transition: .2s ease;
    }
    &.on {
        .control {
            left: 33px;
        }
    }
}

@keyframes fade-in {
    0% {
        opacity:0;
        transform: scale(.95);
    }
    100% {
        opacity:1;
        transform: scale(1);
    }
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .logo {
        height: 40px;
        width: auto;
    }
    .dropdown-wrapper > p > span {
        color: #fff;
    }

    .dropdown-wrapper {
        position: relative;
        cursor: pointer;
        p {
            margin: 0;
            display: flex;
            align-items: center;
            transition: .2s ease;
            padding-bottom: 20px;
            margin-bottom: -20px;
            .material-icons {
                margin-right: 5px;
            }
        }
        &:hover {
            p {
                opacity: .6;
            }
            .dropdown {
                opacity: 1;
                z-index: 990;
                transform: scale(1);
            }
        }
        .dropdown {
            transition: .2s ease;
            transform: scale(0);
            opacity: 0;
            // visibility: hidden;
            position: absolute;
            top: 150%;
            right: -15px;
            padding: 15px;
            background-color: rgba(255, 255, 255, 0.82);
            backdrop-filter: saturate(180%) blur(20px);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            cursor: default;
            box-shadow: 0 5px 50px rgba(0,0,0,.1);
            z-index: -990;
            .item {
                color: $primary-color;
                font-size: 14px;
                width: max-content;
                position: relative;
                display: flex;
                align-items: center;
                margin: 0 0 10px;
                cursor: pointer;
                .material-icons {
                    font-size: 16px;
                    color: inherit;
                    margin-right: 5px;
                }
                transition: .2s ease;
                &:hover {
                    color: $blue;
                }
            }
            &:before {
                content: "";
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                width: 10px;
                height: 5px;
                background-color: rgba(255, 255, 255, 0.82);
                backdrop-filter: saturate(180%) blur(20px);
                position: absolute;
                top: -5px;
                right: 15px;
            }
        }

        .user {
            color: $primary-color;
            font-size: 12px;
            width: max-content;
            position: relative;
            display: flex;
            align-items: center;
            margin: 5px -15px 0;
            padding: 15px 15px 0;
            border-top: 1px solid rgba(87, 95, 127, .2);
        }
    }

    @media (max-width: 640px) {
        padding-left: 15px;
        padding-right: 15px;
        .logo {
            height: 15px;
        }
        .dropdown-wrapper {
            .dropdown {right: -8px;}
            p {
                > span.material-icons {margin: 0;}
                > span:last-child {display: none}
            }
        }
    }

}

.limit-reached-info {
    margin: 30px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        margin: 0;
        font-size: 18px;
        color: $secondary-color;
    }
    span {
        margin-right: 10px;
        color: $yellow;
    }
    @media (max-width: 640px) {
        p {
            font-size: 14px;
        }
    }
}

.installation-steps {
    ol {
        padding-left: 20px;
        margin: 40px 0 0;
        text-align: left;
        li {
            font-size: 18px;
            margin: 0 0 15px;
            &:last-child {
                margin: 0;
            }
            code {
                display: inline-block;
                width: 100%;
                margin: 15px 0 0;
                padding: 10px 44px 10px 20px;
                background: #fff;
                border-radius: 4px;
                border: 1px solid rgba(0,0,0,.05);
                word-break: break-all;
                font-family: 'Roboto Mono', monospace;
                font-size: 14px;
                position: relative;
                line-height: 1.5;
            }
        }
    }
    form {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        z-index: -999;
    }
    .primary-button {
        min-width: 180px;
    }
    .copy-button {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 24px;
        height: 24px;
        background: rgba(0,0,0,.05);
        background-color: rgb(90, 147, 255, .1);
        border-radius: 50%;
        text-align: center;
        line-height: 24px;
        font-size: 14px;
        transform: translateY(-50%);
        cursor: pointer;
        color: $blue;
        transition: .2s ease;
        &:hover {
            background-color: rgb(90, 147, 255, .15);
        }
        &.disabled {
            cursor: default;
        }
    }

    @media (max-width: 640px) {
        ol {
            li {
                font-size: 14px;
            }
        }
    }
}

.installation-instructions {
    text-align: center;
    .text-wrapper,
    .info-box {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .info-box {
        > .text-wrapper {
            .material-icons {
                margin-right: 0;
                margin-bottom: 40px;
                background-color: rgba(90, 147, 255, .1);
            }
        }
    }
}

.no-item-added {
    p {
        display: flex;
        align-items: center;
        margin: 0 0 40px;
        font-size: 18px;
        .material-icons {
            margin-right: 10px;
        }
    }
}

@media (max-width: 540px) {
    .primary-button {
        font-size: 16px;
        height: 45px;
        line-height: 24px;
    }
}