.form-input {
  width: 100%;
  background-color: $input-background;
  border-radius: 4px;
  height: 50px;
  padding: 10px 20px;
  @extend .primary-color;
  font-size: 18px;
  transition: .2s ease;
  box-shadow: 0 0 0 1px rgba(167, 170, 249, .3);
  border: none;
  font-family: inherit;
  line-height: normal;
  &:active,
  &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(167, 170, 249, .5);
      background-color: rgba(255,255,255, 1);
  }
  &.has-error {
      box-shadow: inset 0 0 0 1px $error-red !important;
  }
  &.read-only {
      background-color: rgba(255,255,255,.3);
      box-shadow: 0 0 0 1px rgba(167, 170, 249, .1);
      cursor: default;
  }
}

.faq-form {
  display: block;
  width: 100%;
  text-align: left;
  .general-form-error {
    margin: 0 0 30px;
    color: $error-red;
    font-size: 18px;
    i {
      margin-right: 5px;
    }
    a {
      color: inherit;
      border-bottom: 1px dashed;
    }
    .fake-link {
      cursor: pointer;
      color: inherit;
      border-bottom: 1px dashed;
    }
  }
  .general-form-success {
    margin: 0 0 30px;
    color: $green;
    font-size: 18px;
    i {
      margin-right: 5px;
    }
    a {
      color: inherit;
      border-bottom: 1px dashed;
    }
    .fake-link {
      cursor: pointer;
      color: inherit;
      border-bottom: 1px dashed;
    }
  }
  .fieldset {
    padding: 0 0 30px;
    position: relative;
    &.no-padding {
      padding:0;
    }
    label {
      font-size: 14px;
      // @extend .primary-color;
      margin: 0 0 5px;
      @media (max-width:640px) {
        font-size: 12px;
      }
    }
    .input {
      @extend .form-input;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url('../../images/chevron-down.svg');
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: 98% 50%;
      @media (max-width:640px) {
        background-size: 10px;
      }
    }
    textarea.input {
      resize: none;
      height: 100px;
    }
    .error {
      position: absolute;
      font-size: 12px;
      bottom: 10px;
      left: 0;
      color: $error-red;
      i {
        margin-right: 5px;
        font-size: 10px;
      }
    }
    .password-action {
      display: inline-block;
      font-size: 24px;
      position: absolute;
      right: 20px;
      bottom: 44px;
      @extend .primary-color;
      cursor: pointer;
      i {
        position: relative;
      }
      &.hide {
        i {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 23px;
            width: 2px;
            height: 32px;
            background: $primary-color;
            transform: rotate(45deg);
            transform-origin: top right;
            border-right: 2px solid #4b556e;
            opacity: 1;
            transition: 0.2s ease;
          }
        }
      }
    }
  }
  label,
  input,
  button {
    display: block;
    font-family: $default-font-family;
  }
  input {
    -webkit-appearance: none;
    appearance: none;
    border: none;
  }
  .primary-button {
    width: 100%;
    outline: none;
    border: none;
  }
  .one-column {
    .fieldset {
      width: 100%;
    }
  }
  .two-columns {
    .fieldset {
      display:inline-block;
      vertical-align: middle;
      &.align-top {
        vertical-align: top;
      }
    }
    .fieldset {
      width: calc(50% - 15px);
      &:first-child {
        margin-right:30px;
      }
    }
    @media (max-width: 992px) {
      .fieldset {
        width: 100%;
        &:first-child {
          margin-right:0;
        }
      }
    }
    &.widget-icon-select {
      display: flex;
      align-items: flex-end;
      label {
        margin-left: -60px;
      }
      .fieldset {
        width: 100%;
        &:last-child {
          margin-left: 10px;
          width: calc(100% - 60px);
          order: 2;
        }
      }
      @media (max-width:640px) {
        label {
          margin-left: -44px;
        }
        .fieldset {
          &:last-child {
            width: calc(100% - 44px);
          }
        }
      }
    }
  }
  .three-columns {
    .fieldset {
      width: calc(100% / 3 - 20px);
      display: inline-block;
      vertical-align: middle;
      &:nth-child(2) {
        margin:0 30px;
      }
    }
    @media (max-width: 992px) {
      .fieldset {
        width: 100%;
        &:nth-child(2) {
          margin:0;
        }
      }
    }
  }
  &.inside-card {
    margin-bottom: -30px;
  }
  .has-info-tooltip {
    display: flex;
    align-items: center;
    .info-tooltip-wrapper {
      margin-left: 10px;
    }
  }
  .color-field {
    .color-view {
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      @media (max-width:640px) {
        width: 34px;
        height: 34px;
        padding: 0;
      }
    }
    .input {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 60px);
      @media (max-width:640px) {
        width: calc(100% - 44px);
      }
    }
    .sketch-picker {
      position: absolute;
      left: -230px;
      margin: 0;
      top: 50%;
      z-index: 11;
      transform: translateY(-50%);
      box-shadow: 0 3px 50px rgb(0,0,0, .1) !important;
      .flexbox-fix {
        &:nth-child(3) {
          display: none !important;
        }
        &:nth-child(2) {
          > div {
            &:first-child {
              padding: 10px 0 !important;
              > div {
                &:last-child {
                  display: none;
                }
              }
            }
            &:last-child {
              display: none;
            }
          }
        }
      }
      @media (max-width: 1024px) {
        left: 30px;
      }
      @media (max-width: 360px) {
        width: 180px !important;
      }
    }
  }
  .icon-preview {
    display: inline-block;
    vertical-align: bottom;
    width: 50px;
    height: 50px;
    margin: 0 0 30px;
    padding: 0;
    position: relative;
    border-radius: 50%;
    svg {
      position: absolute;
      width: 30px;
      max-height: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      path {
        fill: $primary-color;
      }
    }
    @media (max-width:640px) {
      width: 34px;
      height: 34px;
      padding: 0;
      svg {
        position: absolute;
        width: 18px;
        max-height: 18px;
      }
    }
  }
}

.input-label {
  font-size: 14px;
  @extend .primary-color;
  margin: 0 0 5px;
  display: inline-block;
}

.checkbox-wrapper {
  display: inline-block;
  cursor: pointer;
  .checkbox, .label {
    display: inline-block;
    vertical-align: middle;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: rgba(255,255,255,.10);
    text-align: center;
  }
  .label {
    margin: 0 0 0 20px;
    font-size: 18px;
  }
  &.checked {
    .checkbox {
      background: rgba(255,255,255,.30);
      i {
        font-size: 11px;
      }
    }
  }
}

.radio-button-wrapper {
  display: inline-block;
  cursor: pointer;
  .radio, .label {
    display: inline-block;
    vertical-align: middle;
  }
  .radio {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 1px rgba(167, 170, 249, .3);
    text-align: center;
    transition: .2s ease;
    position: relative;
    &::before {
      content: '';
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $blue;
      opacity: 0;
      transition: .2s ease;
    }
  }
  .label {
    margin: 0 0 0 10px;
    font-size: 18px;
  }
  &.checked {
    .radio {
      box-shadow: 0 0 0 1px $blue;
      &::before {
        opacity: 1;
      } 
    }
  }
}

.position-field {
  .radio-button-wrapper {
    min-width: 136px;
  }
  .margin-wrapper {
    max-width: 300px;
    .fieldset {
      max-width: 135px;
      .input {
        appearance: none;
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    .radio-button-wrapper {
      .label {
        font-size: 14px;
      }
      &:first-child {
        order: 1;
      }
      &:last-child {
        order: 2;
        margin: 20px 0;
      }
    }
    .faq-form {
      &:nth-child(2) {
        order: 3;
      }
    }
    .flex-row {
      flex-direction: column;
      align-items: flex-start;
      .fieldset {
        padding: 0 0 20px;  
      }
    }
  }
}

.custom-file-input {
  label {
    margin: 0;
  }
  input {
    display: none;
  }
  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    flex-direction: column;
    p {
      margin: 0;
      font-size: 14px;
      i {
        display: inline-block;
        vertical-align: -2px;
        font-size: 16px;
        margin-left: 5px;
      }
    }
    img {
      max-height: 100%;
      margin:0;
    }
    &.logo-field {
      height: 252px;
      padding:50px 20px;
      .logo-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        height: 100px;
        justify-content: center;
      }
    }
  }
}

.field-with-toggle {
  .input {
    height: auto;
    padding: 20px;
    p {
      margin: 0;
      font-size: 14px;
    }
  }
}

.categories-form {
  padding-right: 40px;
  position: relative;
  .delete-category {
    position: absolute;
    right: 0;
    bottom: 43px;
    color: $blue;
    opacity: .5;
    transition: .2s ease;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.questions-form {
  position: relative;
  .actions {
    position: absolute;
    right: 15px;
    bottom: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      font-size: 0;
      > span {
        color: $blue;
        opacity: .5;
        transition: .2s ease;
        margin: 0 5px;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .input {
    span {
      &:not(.material-icons) {
        width: 75%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }
  @media (max-width: 640px) {
    .actions {
      right: 5px;
      width: 50px;
      bottom: 40px;
      > div {
        > span {
          font-size: 18px;
        }
      }
    }
  }
}


@media (max-width: 640px) {
  .form-input {
    height: auto;
    font-size: 14px;
    padding: 8px 16px;
    line-height: 1.2;
  }
  .faq-form {
    .fieldset {
      .input {
        height: auto;
        font-size: 14px;
        padding: 8px 16px;
        line-height: 1.2;
        height: 35px;
      }
      .password-action {
        right: 10px;
        bottom: 35px;
      }
      .error {
        font-size: 10px;
        bottom: 14px;
        i {
          display: none;
        }
      }
    }
    .general-form-error {
      margin: 5px 0 20px;
      font-size: 14px;
    }
  }
}

.input-toggle {
  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
    }
  }
}

.avatar-input {
  &.loading {
    .avatar {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: #fff;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
  > div {
    display: flex;
    align-items: center;
    margin: 5px 0 0;
  }
  input {
    display: none;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: $input-background;
    box-shadow: 0 0 0 1px rgba(167, 170, 249, 0.3);
    position: relative;
    overflow: hidden;
    .loader {
      margin: -25px 0 0 -25px;
      z-index: 10;
      width: 50px;
      height: 50px;
      border-top: 5px solid rgba(90, 147, 255, 0.5);
      border-right: 5px solid rgba(90, 147, 255, 0.5);
      border-bottom: 5px solid rgba(90, 147, 255, 0.5);
      border-left: 5px solid #5A93FF;
    }
  }
  .secondary-button.medium-button {
    min-width: 100px;
    margin: 0 20px;
  }
}