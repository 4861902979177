.perspective-wrapper {
    perspective: 200px;
}
.auth {
    text-align: center;
    max-width: 540px;
    margin: 0 auto;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    .logo {
        height: 40px;
    }
    .form {
        margin:50px 0;
        .animated-wrapper {
            animation: fade-in .7s ease;
            perspective: 200px;
        }
        .auth-tabs {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            .tab {
                @extend .inactive-tab-background;
                @extend .secondary-color;
                font-size:20px;
                height: 60px;
                padding: 18px 20px;
                line-height: 24px;
                border-radius: 4px 4px 0 0;
                width: 100%;
                &.active {
                    @extend .card-background;
                    @extend .primary-color;
                }
            }
        }
    }
    .form-wrapper  {
        padding: 50px 40px;
        @extend .card-background;
        border-radius:0 0 4px 4px;
        &.full-radius {
            border-radius: 4px;
        }
        .form-title {
            text-align: center;
            h2 {
                margin: 0 0 20px;
                font-weight: 400;
                @extend .primary-color;
                font-size: 28px;
            }
            p {
                margin: 0 0 60px;
                @extend .secondary-color;
                font-size: 18px;
                line-height: 24px;
            }
        }
        .back {
            margin:20px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                font-size: 20px;
                margin-right: 10px;
            }
        }
        &.account-confirmation {
            min-height: 400px;
            img {
                margin: 0 0 50px;
            }
        }
        &.reset-password {
            min-height: 400px;
            img {
                margin: 0 0 50px;
            }
        }
    }
    .forgot {
        text-align:right;
        margin:-20px 0 0;
        position: relative;
        z-index: 1;
        a {
            font-size:14px;
            @extend .primary-color;
            margin:0 0 30px;    
            display: inline-block;
            line-height: 24px;
            transition: opacity .2s;
            &:hover {
                opacity: .8;
            }
        }
    }
    .eula {
        margin:50px 0 0;
        font-size: 18px;
        @extend .secondary-color;
        line-height: 26px;
        padding: 0 30px;    
        a {
            @extend .primary-color;
            transition: opacity .2s;
            &:hover {
                opacity: .8;
            }
        }
    }
    .copyrights {
        margin:0;
        font-size: 18px;
        line-height: 26px;
        color:#fff;
    }
}

@media (max-width: 540px) {
    .auth {
        padding: 30px 10px;
        .form-wrapper {
            padding: 30px 15px;
            .form-title {
                h2 {
                    font-size: 18px;
                    margin: 0 0 10px;
                }
                p {
                    margin: 0 0 30px;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
        .form {
            .auth-tabs {
                gap: 10px;
                .tab {
                    font-size: 16px;
                    height: 50px;
                    padding: 14px 20px;
                }
            }
        }
        .copyrights {
            font-size: 16px;
            line-height: 24px;
        }
        .forgot a {
            margin: 15px 0;
        }
    }
}