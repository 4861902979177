.navigation {
    @extend .card-background;
    padding: 75px 0 125px;
    width: 240px;
    text-align: center;
    position: relative;
    .nav-item {
        padding: 25px 30px;
        background-color: transparent;
        @extend .primary-color;
        opacity: .4;
        transition: opacity .2s ease;
        cursor: pointer;
        .icon {
            font-size: 60px;
            margin:0 0 10px;
            display: inline-block;
        }
        span {
            font-size:18px;
            display: block;
        }
        &:hover {
            opacity: .6;
        }
        &.active {
            opacity: 1;            
            cursor: default;
        }
    }
    .logout {
        cursor: pointer;
        position: absolute;
        bottom: 30px;
        transition: .2s ease;
        left: 50%;
        transform: translateX(-50%);
        i {
            margin-left: 10px;
            font-size: 12px;
        }
        &:hover {
            opacity: .6;
        }
    }

    @media (max-width: 1024px) {
        padding: 50px 0;
        width: 40px;
        .nav-item {
            padding: 10px 5px;
            .icon {
                font-size: 24px;
                margin: 0;
            }
            span {
                display: none;
            }
        }
    }
}