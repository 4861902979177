.light-theme {
    .gradient-background {
        background: $background-color-light;
    }
    .primary-color {
        color:$primary-color-light;
    }
    .secondary-color {
        color:$secondary-color-light;
    }
    .card-background {
        background:$primary-color;
    }
    .card-box-shadow {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
    }
    .form-input {
        background: $primary-color;
        box-shadow:inset 0 0 0 1px $border-color-light;
        &:active,
        &:focus {
            box-shadow: inset 0 0 0 1px $secondary-color-light;
        }
    }
    .inactive-tab-background {
        background: rgba(255,255,255,.4);
    }
    .navigation {
        .nav-item.active {
            @extend .gradient-background;
        }
    }
    .switcher {
        box-shadow: none;
    }
}