.primary-color {
    color:$primary-color;
}
.secondary-color {
    color:$secondary-color;
}
.gradient-background {
    background: linear-gradient(45deg, #5A93FF, #69CBFF, #FF53E4)
}
.card-background {
    background:$card-background;
}
.card-box-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.inactive-tab-background {
    background: $disabled-tab;
}
.text-green {
    color:$green;
}

.text-warning {
    color: $warning;
}

.text-blue {
    color: $blue;
}

.text-yellow {
    color: $yellow;
}

::placeholder {color:rgba(64, 74, 101, .4);}
:-ms-input-placeholder {color:rgba(64, 74, 101, .4);}
::-ms-input-placeholder {color:rgba(64, 74, 101, .4);}