.alerts {
    position: fixed;
    z-index: 999;
    width: 320px;
    right: 40px;
    bottom: 40px;
    .notification {
        background-color: #fff;
        position: relative;
        margin: 0 0 20px;
        border-radius: 6px;
        box-shadow: 0 10px 80px rgba(0, 0, 0, .2);
        animation: fade-in .5s ease;
        text-align: left;
        overflow: hidden;
        .notification-wrapper {
            position: relative;
            padding: 20px 20px 20px 60px;
            i {
                position: absolute;
                left: 15px;
                top: 50%;
                font-size: 20px;
                transform: translateY(-50%);
            }
            h4 {
                margin: 0 0 5px;
                font-size: 16px;
                font-weight: 600;
            }
            p {
                margin: 0;
                color:$secondary-color;
                font-size: 14px;
            }
        }
        &.success {
            i {
                color:$green;
            }
        }
        &.error {
            i {
                color:$error-red;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        i.icon-close {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 7px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            color: $primary-color;
            text-align: center;
            line-height: 18px;
            transition: .2s ease;
            z-index: 2;
            cursor: pointer;
            &:hover {
                opacity: .6;
            }
        }
    }
}

.header-actions {
    width: 100%;
    border-left: 2px solid transparent;
    // position: sticky;
    // top: 0;
    z-index: 999;
    // backdrop-filter: saturate(180%) blur(20px);
    border-top-left-radius: 20px;
    > div {
        @extend .card-background;
        padding: 10px 40px;
        height: 60px;
        margin-bottom: 1px;
        position: relative;
        @media (min-width: 641px) and (max-width: 1024px) {
            padding: 10px 20px;
            height: auto;
        }
        @media (max-width: 640px) {
            padding: 10px;
            height: auto;
            .flex-row {
                flex-direction: column;
                align-items: self-end;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        p {
            display: inline-block;
            width: auto;
            font-size: 16px;
            margin: 0;
            line-height: 38px;
        }
        .header-action {
            display: inline-block;
            width: auto;
            font-size: 16px;
            margin-right: 50px;
            cursor: pointer;
        }
        .transparent-button {
            display: inline-block;
            width: auto;
            min-width: 200px;
            border-radius: 2px;
            font-size: 16px;
            height: 40px;
            line-height: 18px;
            padding: 10px 20px;
            text-align: center;
            color: $primary-color;
            cursor: pointer;
            background:rgba(255,255,255,.1);
            transition: background .2s ease;
            position: relative;
            &:hover {
                background:rgba(255,255,255,.2);
            }
            &.disabled {
                cursor: default;
                background:rgba(255,255,255,.5);
                color: $card-background;
            }
        }
    }
    .dashboard-tabs.no-border-bottom {
        display: inline-block;
        padding: 0;
        margin: 15px 0 0 0;
        span {
            margin: 0 10px;
        }
    }
    .link {
        margin-right:40px;
        @media (max-width: 640px) {
            font-size:14px;
            margin-right:0;
            margin-bottom: 10px;
        }
    }
}

.placeholder {
    display: inline-block;
    padding: 14px 40px;
    border: 1px dashed;
    cursor: pointer;
    transition: opacity .2s ease;
    &:hover {
        opacity: .7;
    }
    &.size-s {
        font-size:18px;
    }
    &.size-m {
        font-size:20px;
    }
    &.size-l {
        font-size:28px;
    }
}

// Modal
.modal-open {
    .modal-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
        z-index: 1000;
        // background-color: rgba(255, 255, 255, 0.6);
        background-color: rgba(255, 255, 255, 0.72);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        animation: opacityAnimation .1s ease;
    }
    .modal {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        text-align: center;
        top: 0;
        left: 50%;
        z-index: 1001;
        transform: translateX(-50%);
        animation: opacityAnimation .3s ease;
        // display: flex;
        // align-items: center;
        padding: 20px;
    }
    .modal-card {
        display: inline-block;
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1002;
        padding: 40px;
        background: $notification-background;
        color: $primary-color;
        border-radius: 6px;
        overflow: hidden;
        margin: 50px auto;
        box-shadow: 0 5px 50px rgba(0,0,0,.1);
        max-width: 480px;
        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 16px;
            cursor: pointer;
        }
        &.size-m {
            max-width: 600px;
        }
        &.size-l {
            max-width: 800px;
        }
        &.size-xl {
            max-width: 1000px;
        }
        .title {
            margin: 0 0 10px;
            font-size: 20px;
        }
        .subtitle {
            margin: 0;
        }
        .buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 40px 0 0;
            > div {
                width: auto;
                min-width: 100px;
                margin:0 10px;
            }
        }
        .modal-icon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: rgba(90, 147, 255, .1);
            text-align: center;
            line-height: 60px;
            font-size: 35px;
            margin: 0 0 40px;
        }
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
}

// Template style
.content-editor {
    .page-content {
        max-width: 1200px;
        margin: 0 auto;
        background: #fff;
        min-height: 1400px;
        border-radius: 20px;
        overflow: hidden;
    }
    .page-header {
        text-align: center;
        padding: 20px;
        .logo {
            margin:0 0 60px;
        }
    }
}

@keyframes opacityAnimation {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

// Info Tooltip
.info-tooltip-wrapper {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    vertical-align: 5px;
    line-height: 1;
    font-size: 0;
    cursor: default;
    span {
        transition: .2s ease;
        font-size: 16px;
        vertical-align: middle;
    }
    .tooltip {
        position: absolute;
        bottom: 30px;
        width: 230px;
        height: auto;
        background: #5A93FF;
        color: #fff;
        padding: 15px;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
        transition: .2s ease;
        opacity: 0;
        visibility: hidden;
        font-size: 14px;
        line-height: 18px;
        &:before {
            content: '';
            position: absolute;
            bottom: -7px;
            width: 14px;
            height: 14px;
            background: #5A93FF;
            transform: rotate(45deg);
            left: 50%;
            margin-left: -7px;
        }
        p {
            font-size: 14px;
            line-height: 24px;
        }
    }
    &:hover {
        .tooltip {
            visibility: visible;
            opacity: 1;
        }
        span {
            opacity: .6;
        }
    }
    &.arrow-bottom {
        .tooltip {
            bottom: auto;
            top: 35px;
            background: #585f7e;
            &::before {
                bottom: auto;
                top: -7px;
                background: #585f7e;
            }
        }
    }
    &.header-info {
        width: auto;
        height: auto;
        margin-right: 20px;
        > span {
            font-size: 24px;
            color: inherit;
        }
    }
}

.info-box {
    max-width: 1600px;
    margin: 80px auto 0;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .text-wrapper {
        display: flex;
        align-items: center;
        .material-icons {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.3);
            text-align: center;
            line-height: 100px;
            font-size: 50px;
            margin-right: 40px;
        }
        h2, h3, h4, h5, h5 {
            margin: 0 0 20px;
        }
        p {
            margin: 0;
        }
    }
    // box-shadow: 0 10px 80px rgba(0, 0, 0, .1);
}

// Categories
.categories-editor-body {
    .no-data {
        display: flex;
        align-items: center;
        font-size: 20px;
        margin: 0;
        span {
            margin-right: 10px;
            color: $blue;
        }
    }
    .buttons-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 40px 0 0;
        .primary-button {
            box-shadow: none;
        }
        .secondary-button {
            margin-right: 10px;
        }
    }
    .faq-form {
        .fieldset {
            &:first-child {
                width: calc(30% - 15px);
                margin-right:30px;
            }
            &:last-child {
                width: calc(70% - 15px);
            }
        }
    }
}

// Categories
.questions-editor-body {
    .faq-form {
        .fieldset {
            &:last-child {
                width: calc(30% - 15px);
            }
            &:first-child {
                width: calc(70% - 15px);
                margin-right:30px;
            }
        }
    }

    @media (max-width: 640px) {
        .faq-form {
            .fieldset {
                &:last-child {
                    width: 100%;
                }
                &:first-child {
                    width: 100%;
                    margin-right:0;
                }
            }
        }
    }
}

// Rich-text
.rich-text-editor-wrapper {
    width: 100%;
    background-color: $input-background;
    border-radius: 4px;
    padding: 20px;
    @extend .primary-color;
    font-size: 18px;
    transition: .2s ease;
    box-shadow: 0 0 0 1px rgba(167, 170, 249, .3);
    border: none;
    font-family: inherit;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    .rich-text-action-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .rich-text-action {
        cursor: pointer;
        margin-right: 15px;
        display: inline-block;
        transition: .2s ease;
        font-size: 20px;
        &:hover {
            opacity: .6;
        }
        &:last-child {
            margin-right: 0;
        }
        &.rich-text-action-active {
            color: $blue;
        }
    }
    .rich-text-editor {
        border-top: 1px solid rgba(167, 170, 249, .3);
        cursor: text;
        font-size: 16px;
        margin-top: 20px;
        .public-DraftEditorPlaceholder-root,
        .public-DraftEditor-content {
            margin: 0 -20px -20px;
            padding: 20px;
            min-height: 100px;
            > div {
                > h1, > h2, > h3, > h4, > h5, > h6, > p, > div, > ol, > ul, > pre, > blockquote {
                    margin: 0 0 20px;
                }
                h1 {
                    font-size: 24px;
                }
                h2 {
                    font-size: 22px;
                }
                h3 {
                    font-size: 20px;
                }
                h4 {
                    font-size: 18px;
                }
                h5 {
                    font-size: 16px;
                }
                h6 {
                    font-size: 14px;
                }
                > * {
                    &:last-child {
                        margin: 0;
                    }
                }
                > p, > div, > blockquote, > ul, > ol {
                    font-size: 14px;
                }
            }
        }
        .editor-blockquote {
            border-left: 5px solid rgba(0, 0, 0, 0.05);
            font-style: italic;
            margin: 16px 0;
            padding: 10px 20px;
        }
        .public-DraftStyleDefault-pre {
            border-radius: 4px;
            overflow: hidden;
            * {
                word-break: break-all;
                display: flex;
                text-overflow: ellipsis;
                white-space: break-spaces;
                line-height: 1.5;
            }
            span {
                font-size: 14px;
                padding: 4px 10px;
                display: inline-block;
                width: 100%;
                max-width: 100%;
            }
            pre {
                margin: 0;
                padding: 0;
                background: transparent;
            }
        }
    }
    .RichEditor-controls {
        font-size: 0;
        user-select: none;
    }

    @media (max-width: 640px) {
        padding: 10px;
        .rich-text-editor {
            margin-top: 10px;
            .public-DraftEditor-content {
                padding: 20px 0;
                margin: 0;
            }
        }
        .rich-text-action-row {
            flex-direction: column;
            align-items: flex-start;
        }
        .rich-text-action {
            margin: 0;
            padding: 7px;
            &:last-child {
                margin-right: 5px;
            }
        }
    }
}

// Custom select
.faq-form {
    .input.custom-select {
        position: relative;
        padding-right: 44px;
        .icon {
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 10px;
            transition: .2s ease;
        }
        .click-area {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            z-index: 1;
        }
        .options {
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            width: 100%;
            height: auto;
            max-height: 150px;
            background-color: #fff;
            border-radius: 4px;
            padding: 10px;
            @extend .primary-color;
            font-size: 18px;
            transition: .2s ease;
            box-shadow: 0 0 0 1px rgba(167, 170, 249, .3);
            border: none;
            font-family: inherit;
            display: none;
            overflow: auto;
            .option {
                cursor: pointer;
                padding: 5px 0;
                transition: .2s ease;
                &:hover {
                    opacity: .6;
                }
            }
            @media (max-width: 640px) {
                font-size: 14px;
            }
        }
        .select-placeholder {
            line-height: normal;
        }
        &.opened {
            .icon {
                transform: rotateX(180deg);
            }
            .options {
                display: block;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1280px) {
    
}



.chat-wrapper {
    padding: 40px;
    max-width: 600px;
    margin: 0 auto;
    .message {
        margin: 20px 0 25px;
        font-size: 14px;
        line-height: 1.4;
        position: relative;
        padding: 10px 20px;
        border-radius: 50px;
    }
    .sent {
        background: #5a93ff;
        color: #fff;
        max-width: 90%;
        margin-right: 0;
        margin-left: auto;
        // border-bottom-right-radius: 0;
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 12px 5px 0 5px;
            border-color: #5a93ff transparent transparent transparent;
            border-style: solid;
            right: 1px;
            bottom: -2px;
            transform: rotate(-45deg);
        }
    }
    .received {
        background: #fafafa;
        max-width: 90%;
        margin-right: auto;
        margin-left: 0;
        // border-bottom-left-radius: 0;
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 12px 5px 0 5px;
            border-color: #fafafa transparent transparent transparent;
            border-style: solid;
            left: 1px;
            bottom: -2px;
            transform: rotate(45deg);
        }
    }
    .faq-form {
        padding: 20px 0 0;
        border-top: 1px solid rgba(0,0,0,.1);
    }
}


.conversations-wrapper {
    display: flex;
    justify-content: stretch;
    flex-direction: row;
    height: 100%;

    .conversation {
        flex: 1;
        max-height: 910px;
        overflow: auto;
        .chat {
            max-width: 800px;
            margin: 75px auto;
            padding: 0 20px 20px;
        }
    }

    .session-date {
        // display: flex;
        display: none;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        &::after,
        &::before {
            content: '';
            height: 1px;
            opacity: .1;
            background-color: #575f7f;
            flex: 1;
        }
        &::before {
            margin-right: 20px;
        }
        &::after {
            margin-left: 20px;
        }
    }

    .message-wrapper {
        margin: 40px 0;
        display: flex;
        flex-direction: column;
    }
    .message-timestamp {
        padding: 5px 10px 0;
        font-size: 12px;
        opacity: .36;
        display: inline-block;
        width: 100%;
    }
    .message {
        width: auto;
        max-width: 85%;
        font-size: 14px;
        line-height: 1.4;
        position: relative;
        padding: 10px 20px;
        border-radius: 25px;
    }
    .received {
        .message {
            background: #fff;
            margin-left: 0;
            margin-right: auto;
            &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-width: 12px 5px 0 5px;
                border-color: #fff transparent transparent transparent;
                border-style: solid;
                left: 1px;
                bottom: -4px;
                transform: rotate(45deg);
            }
        }
    }
    .sent {
        .message {
            color: #fff;
            background: #5A93FF;
            margin-right: 0;
            margin-left: auto;

            &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-width: 12px 5px 0 5px;
                border-color: #5a93ff transparent transparent transparent;
                border-style: solid;
                right: 1px;
                bottom: -4px;
                transform: rotate(-45deg);
            }
        }
        .message-timestamp {
            text-align: right;
        }
    }
}


.conversations-nav {
    padding: 75px 0;
    width: 200px;
    text-align: center;
    position: relative;
    background: rgba(255,255,255,.3);
    margin: 0 0 0 2px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .conversation-list {
        > div {
            padding: 10px 15px;
            margin: 10px;
            font-size: 18px;
            transition: .2s ease;
            opacity: 0.4;
            border-radius: 4px;
            font-size: 14px;
            a {
                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: end;
                }
            }
            .time {
                font-size: 12px;
                opacity: .6;
            }
            &:hover {
                opacity: 0.6;
                background: rgba(255,255,255,.5);
            }
            &.active {
                opacity: 1;
                background: rgba(255,255,255,.6);
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
            margin: 2px
        }
        .current-page {
            font-size: 14px;
            margin: 0 10px;
            line-height: 14px;
            height: 14px;
        }
        .material-icons,
        .action {
            font-size: 16px;
            width: 16px;
            height: 16px;
            line-height: 16px;
        }
        .crs-pointer {
            transition: .2s ease;
            &:hover {
                opacity: .6;
            }
        }
    }
}

.conversations {
    position: relative;
    height: 100%;
    .loader-overlay {
        position: absolute;
        background: transparent;
        &:after {
            display: none;
        }
        .loader {
            border-top: 3px solid rgba(90, 147, 255, .2);
            border-right: 3px solid rgba(90, 147, 255, .2);
            border-bottom: 3px solid rgba(90, 147, 255, .2);
            border-left: 3px solid rgba(90, 147, 255, .5);
        }
    }
    .date {
        max-width: 65%;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
    }
}